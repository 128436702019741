import classNames from "classnames";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import { ReactComponent as Heart } from "../../assets/heart.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as ShoppingCart } from "../../assets/shopping-cart.svg";
import { ReactComponent as User } from "../../assets/user.svg";
import "./navbar.scss";

const newMenuItems = [
  {
    pathname: "/find-patient",
    logo: <User />,
  },
  {
    pathname: "/create-treatment-plan",
    logo: <Heart />,
  },
  {
    pathname: "/add-treatment-plan",
    logo: <ShoppingCart />,
  },
];

//TODO: map items using react, onClick instead of event
export const Navbar = () => {
  const menuRef = useRef(null);
  const menuItemRefs = useRef([]);
  const menuBorderRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const clickItem = (item) => {
    offsetMenuBorder(item, menuBorderRef.current);
  };

  const offsetMenuBorder = (element, menuBorder) => {
    if (!element || !menuBorder) return;
    const activeItem = element.getBoundingClientRect();
    const down =
      Math.floor(
        activeItem.top - (menuBorder.offsetWidth - activeItem.height) / 2
      ) + "px";
    menuBorder.classList.remove("notransition");
    menuBorder.style.transform = `rotate(90deg) translate3d(${down}, 0, 0)`;
  };

  // Set the border without any transition on first render
  const setInitialMenuBorderPos = (element, menuBorder) => {
    if (!element) return;
    const activeItem = element.getBoundingClientRect();
    const down =
      Math.floor(
        activeItem.top - (menuBorder.offsetWidth - activeItem.height) / 2
      ) + "px";
    menuBorder.classList.add("notransition");
    menuBorder.style.transform = `rotate(90deg) translate3d(${down}, 0, 0)`;
  };

  const loadNavbar = () => {
    const menu = menuRef?.current;
    const menuBorder = menu?.querySelector(".menu__border");
    let activeItem = menu?.querySelector(".active");

    setInitialMenuBorderPos(activeItem, menuBorder);

    let url = window.location.href;
    window.addEventListener(
      "click",
      () => {
        requestAnimationFrame(() => {
          if (url !== window.location.href) {
            let activeItem = menu?.querySelector(".active");
            offsetMenuBorder(activeItem, menuBorder);
            url = window.href;
          }
        });
      },
      true
    );
  };

  useEffect(() => {
    loadNavbar();
  }, []);

  return (
    <div className="flex flex-col items-center bg-[#1d1d27] my-5 rounded-xl ml-5">
      <div className="mt-6">
        <Logo />
      </div>

      <menu className="menu" ref={menuRef}>
        {newMenuItems.map((menuItem, i) => {
          return (
            <button
              key={i}
              ref={(element) => {
                menuItemRefs.current.push(element);
              }}
              className={classNames("menu__item", {
                active: location.pathname === menuItem.pathname,
              })}
              onClick={(e) => {
                navigate(menuItem.pathname);
                clickItem(menuItemRefs[i]);
              }}
            >
              {menuItem.logo}
            </button>
          );
        })}
        <div ref={menuBorderRef} className="menu__border"></div>
      </menu>

      <div className="svg-container">
        <svg viewBox="0 0 202.9 45.5">
          <clipPath
            id="menu"
            clipPathUnits="objectBoundingBox"
            transform="scale(0.0049285362247413 0.021978021978022)"
          >
            <path
              d="M6.7,45.5c5.7,0.1,14.1-0.4,23.3-4c5.7-2.3,9.9-5,18.1-10.5c10.7-7.1,11.8-9.2,20.6-14.3c5-2.9,9.2-5.2,15.2-7
          c7.1-2.1,13.3-2.3,17.6-2.1c4.2-0.2,10.5,0.1,17.6,2.1c6.1,1.8,10.2,4.1,15.2,7c8.8,5,9.9,7.1,20.6,14.3c8.3,5.5,12.4,8.2,18.1,10.5
          c9.2,3.6,17.6,4.2,23.3,4H6.7z"
            />
          </clipPath>
        </svg>
      </div>
    </div>
  );
};
