import React, { createContext, useState } from "react";
import { TreatmentPlan } from "../interfaces";

interface Context {
  treatmentPlan: TreatmentPlan;
  updateTreatmentPlan: (treatmentPlan: TreatmentPlan) => void;
  updateTreatmentPlanField: (field: keyof TreatmentPlan, value: any) => void;
}

const defaultContext: Context = {
  treatmentPlan: {
    treatmentProducts: [],
    dispensaryAccess: true,
    patientNotes:
      "It was great to catch up today, I look forward to hearing how you find these medications!",
  },
  updateTreatmentPlan: function (treatmentPlan: TreatmentPlan): void {},
  updateTreatmentPlanField: function (
    field: keyof TreatmentPlan,
    value: any
  ): void {},
};

console.log("Initial Context:");
console.log(defaultContext);
export const TreatmentPlanContext = createContext(defaultContext);

export const TreatmentPlanContextProvider: React.FC<any> = ({ children }) => {
  const [treatmentPlan, setTreatmentPlan] = useState<TreatmentPlan>(
    defaultContext.treatmentPlan
  );
  const updateTreatmentPlan = (treatmentPlan: TreatmentPlan) =>
    setTreatmentPlan(treatmentPlan);

  const updateTreatmentPlanField = (field: keyof TreatmentPlan, value: any) =>
    setTreatmentPlan((treatmentPlan) => ({ ...treatmentPlan, [field]: value }));

  return (
    <TreatmentPlanContext.Provider
      value={{
        treatmentPlan,
        updateTreatmentPlan,
        updateTreatmentPlanField,
      }}
    >
      {children}
    </TreatmentPlanContext.Provider>
  );
};
