import { useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { TreatmentPlanContextProvider } from "./contexts/TreatmentPlanContext";
import { MainLayout } from "./layouts/MainLayout";
import { AddTreatmentPlan } from "./pages/AddTreatmentPlan";
import { Auth } from "./pages/Auth";
import { CreateTreatmentPlan } from "./pages/CreateTreatmentPlan";
import { FindPatient } from "./pages/FindPatient";
import SplashScreen from "./pages/SplashScreen";
import { ViewTreatmentPlan } from "./pages/ViewTreatmentPlan";

//REFACTIRING: routes can be a mapped array
const protectedRoutes = [
  {
    path: "/find-patient",
    component: <FindPatient />,
  },
  {
    path: "/create-treatment-plan",
    component: <CreateTreatmentPlan />,
  },
  {
    path: "/add-treatment-plan",
    component: <AddTreatmentPlan />,
  },
];

function App() {
  const [isUserAuth, setIsAuth] = useState<Boolean>(false);

  const updateAuth = (isUserAuth: boolean) => {
    setIsAuth(isUserAuth);
  };

  return (
    <TreatmentPlanContextProvider>
      <BrowserRouter>
        <MainLayout>
          <Routes>
            <Route path="/" element={<Auth updateAuth={updateAuth} />} />
            <Route
              path="/view-treatment-plan/:id"
              element={<ViewTreatmentPlan />}
            />
            <Route path="/splash-screen" element={<SplashScreen />} />
            {protectedRoutes.map((route, i) => {
              return (
                <Route
                  key={i}
                  path={route.path}
                  element={
                    isUserAuth ? route.component : <Navigate to="/" replace />
                  }
                />
              );
            })}
          </Routes>
        </MainLayout>
      </BrowserRouter>
    </TreatmentPlanContextProvider>
  );
}

export default App;
