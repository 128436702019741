import axios from "axios";
import classNames from "classnames";
import { useContext } from "react";
import { useNavigate } from "react-router";
import ChevronRight from "../assets/chevron-right.svg";
import { TreatmentPlanContext } from "../contexts/TreatmentPlanContext";
import { resolveProductStrength } from "./CreateTreatmentPlan";

//REFACTOR: render funs / components like treatment plan
export const AddTreatmentPlan = () => {
  const navigate = useNavigate();
  const { treatmentPlan, updateTreatmentPlanField, updateTreatmentPlan } =
    useContext(TreatmentPlanContext);

  // Used to map through the treatment plan context array so we can update the specific
  // products quantity value
  const updateQuantity = (productId: number, quantity: number) => {
    const updatedProducts = treatmentPlan.treatmentProducts?.map((product) => {
      if (product.id === productId) {
        return { ...product, quantity };
      }

      return product;
    });

    updateTreatmentPlanField("treatmentProducts", updatedProducts);
  };

  const submit = async () => {
    if (
      !treatmentPlan.appointmentType ||
      !treatmentPlan.patient ||
      !treatmentPlan.practitioner
    ) {
      return alert(
        "You haven't finished selecting a Practitioner/Appointment Type/Patient"
      );
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}treatment-plan`,
        treatmentPlan
      );
      navigate("/splash-screen");
    } catch (err: any) {
      if (err.response.data.message) {
        console.log(err.response.data.message + " " + err.response.status);
        alert(err.response.data.message + " " + err.response.status);
      } else {
        alert(err);
      }
    }
  };

  return (
    <div className="flex">
      <div className="flex flex-col w-full text-4xl mb-8 mt-2 md:text-4xl font-[1000]">
        Add to Treatment Plan
        <div className="text-base mt-12 font-normal">
          Share with patient broad access to dispensary to view available
          products (if requested)
        </div>
        <div className="flex flex-row gap-6 mt-4 text-base font-light">
          <div className="flex items-center">
            <input
              type="radio"
              id="appointmentType"
              name="appointmentType"
              value="new"
              defaultChecked={true}
              onClick={() => updateTreatmentPlanField("dispensaryAccess", true)}
            />
            <div className="mx-2">Yes</div>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              id="appointmentType"
              name="appointmentType"
              value="followUp"
              onClick={() =>
                updateTreatmentPlanField("dispensaryAccess", false)
              }
            />
            <div className="mx-2">No</div>
          </div>
        </div>
        <div className="flex flex-col my-5 text-base">
          {/* 
          <div className="my-3">Follow Up Date</div>
          <div className="flex items-center">
            <div className="border-[1px] rounded-3xl px-2 py-1">
              <input
                id="appointmentType"
                type="date"
                name="appointmentType"
                className="outline-none ml-2"
                checked={treatmentPlan.appointmentType === "new"}
                onChange={(e) => {
                  updateTreatmentPlanField("followUpInterval", e.target.value);
                }}
                style={{ boxShadow: "0" }}
              />
            </div>
          </div> */}
          {/* as the above is commented out, "followUpInterval" will not be assigned, would effect anything?*/}
        </div>
        <div className="w-full">
          <div className="text-base font-normal my-2">Notes for customer:</div>
          <textarea
            className="border border-cc-gray-200 rounded-xl w-full text-base px-3 py-2"
            maxLength={10000}
            onChange={(e) => {
              updateTreatmentPlanField("patientNotes", e.target.value);
            }}
          >
            {treatmentPlan.patientNotes}
          </textarea>
        </div>
        <div className="grid grid-cols-2 gap-7 border border-cc-gray-200 rounded-xl text-sm my-6 py-7 px-12 w-full overflow-y-auto max-h-[65vh]">
          {treatmentPlan.treatmentProducts ? (
            treatmentPlan.treatmentProducts.map((treatmentProduct, i) => {
              let productStrength = resolveProductStrength(
                treatmentProduct.product
              );

              return (
                <div
                  key={i}
                  className={classNames(
                    "flex flex-row w-full px-5 py-7 border border-cc-gray-500 rounded-xl",
                    {
                      "border-cc-high-strength": productStrength === "High",
                      "border-cc-medium-strength": productStrength === "Medium",
                      "border-cc-low-strength": productStrength === "Low",
                    }
                  )}
                >
                  <img
                    src={treatmentProduct.product?.imageSrc}
                    className="max-h-[140px] max-w-[140px] mr-3"
                    alt="ProductImage"
                  />

                  <div>
                    <div className="text-base font-semibold">
                      {treatmentProduct?.product?.name}
                    </div>

                    {productStrength && (
                      <div
                        className={classNames(
                          "my-2 font-bold text-orange-300",
                          {
                            "text-cc-high-strength": productStrength === "High",
                            "text-cc-medium-strength":
                              productStrength === "Medium",
                            "text-cc-low-strength": productStrength === "Low",
                          }
                        )}
                      >
                        {productStrength + " THC"}
                      </div>
                    )}

                    {treatmentProduct.notes && (
                      <div className="mt-2">
                        Notes <br />{" "}
                        <div className="text-cc-gray-100">
                          {treatmentProduct.notes}
                        </div>
                      </div>
                    )}
                    {treatmentProduct.product?.attributes && (
                      <div>
                        Product Variation:{" "}
                        {treatmentProduct?.product?.attributes[0].option}
                      </div>
                    )}

                    <div className="py-1 pl-4 mt-4 font-light border rounded-full border-cc-gray-500">
                      Quantity:
                      <input
                        className="px-1 mx-2 outline-none"
                        min={1}
                        type="number"
                        defaultValue={treatmentProduct.quantity}
                        onChange={(e) =>
                          updateQuantity(treatmentProduct.id, +e.target.value)
                        }
                      ></input>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div>No products selected for the patients treatment plan</div>
          )}
        </div>
        <div className="flex flex-col text-lg pb-12 font-normal">
          <button
            className="flex ml-auto mt-auto items-center px-4 py-2 bg-[#5197DE] text-white text-xs sm:text-sm rounded-3xl disabled:bg-gray-300"
            onClick={() => submit()}
            disabled={!treatmentPlan.treatmentProducts?.length}
          >
            Send to patient
            <img src={ChevronRight} alt="chevronRight" className="ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
};
