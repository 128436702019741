import classNames from "classnames";
import check from "../assets/check.svg";
import { TreatmentProduct } from "../interfaces";

interface SelectButtonInput {
  selected: TreatmentProduct | null; //TODO: boolean
  addProduct: () => void;
  removeProduct: () => void;
}

export const SelectButton: React.FC<SelectButtonInput> = ({
  selected,
  addProduct,
  removeProduct,
}) => {
  return (
    <button
      className={classNames(
        "text-white px-6 py-1 my-1 rounded-2xl justify-center flex items-center",
        {
          "bg-cc-blue-500": selected,
          "bg-cc-gray-300": !selected,
        }
      )}
      onClick={(e) => {
        (selected ? removeProduct : addProduct)();
      }}
    >
      Select
      {!!selected && (
        <img src={check} className="inline ml-3" alt="checkmark" />
      )}
    </button>
  );
};
