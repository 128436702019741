import { useLocation } from "react-router";
import { Navbar } from "../components/Navbar/Navbar";

interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  // const { isUserAuth, setIsUserAuth } = useContext(UserAuthContext);
  const location = useLocation();

  const conditionalLayout = () => {
    if (
      location.pathname === "/" ||
      location.pathname.includes("/view-treatment-plan/") ||
      location.pathname.includes("/splash-screen")
    ) {
      return (
        <div className="flex h-screen w-screen overflow-y-scroll">
          {children}
        </div>
      );
    } else {
      return (
        <div className="flex flex-row h-screen w-screen">
          <Navbar />
          <div className="flex flex-col flex-1 px-4 md:px-14 mt-14 md:mt-24 overflow-y-scroll">
            {children}
          </div>
        </div>
      );
    }
  };

  return <>{conditionalLayout()}</>;
};
