import classNames from "classnames";
import React from "react";
import { FC, useEffect, useRef, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const ProductNotes: FC<{ notes: string | undefined }> = ({ notes }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showNotes, setShowNotes] = useState(false);

  const maxLength = 25;
  const returnTruncatedString = (value: string | undefined): string => {
    if (!value) return "";
    return value.length > maxLength
      ? `${value.substring(0, maxLength)}...`
      : value;
  };

  const handleClick = (event: any) => {
    if (!ref.current) return;
    if (!ref.current.contains(event.target)) setShowNotes(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, []);

  const RenderWithLineBreaks = (text: string | undefined) => {
    if (text === undefined) {
      return <></>;
    }
    // Split the text into an array of lines and map each line to a JSX element
    const lines = text.split("\n").map((line, index, array) => (
      // Add a <br /> after each line except the last one
      <React.Fragment key={index}>
        {line}
        {index < array.length - 1 && <br />}
      </React.Fragment>
    ));

    return <div>{lines}</div>;
  };

  return (
    <div className="relative mt-3 mb-4">
      <>
        <div>Notes</div>
        <div
          ref={ref}
          className={classNames("my-2 font-medium max-w-xs", {
            "cursor-pointer": notes && notes.length > maxLength,
            "cursor-default": !notes || notes.length <= maxLength,
          })}
          onClick={() => {
            notes && notes.length > maxLength && setShowNotes(!showNotes);
          }}
        >
          <div className="flex border-[1px] rounded-lg p-2 px-3 text-cc-gray-100 border-cc-gray-300">
            {showNotes
              ? RenderWithLineBreaks(notes)
              : returnTruncatedString(notes)}
            <div className="ml-auto pt-0.5">
              {showNotes ? (
                <IoIosArrowUp
                  color={
                    notes && notes.length > maxLength ? "black" : "lightGrey"
                  }
                />
              ) : (
                <IoIosArrowDown
                  color={
                    notes && notes.length > maxLength ? "black" : "lightGrey"
                  }
                />
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default ProductNotes;
